import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Faq from 'components/pages/Faq'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function EnterpriseClinicalFaq() {
  useEventPageVisited('Enterprise Clinical FAQ')
  const data = useStaticQuery(graphql`
    query {
      clinical: allMdx(
        filter: { frontmatter: { draft: { ne: true }, template: { eq: "faqClinical" } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        nodes {
          id
          body
          frontmatter {
            title
          }
        }
      }
      all: allMdx(
        filter: {
          frontmatter: { draft: { ne: true }, template: { eq: "faq" }, clinical: { eq: true } }
        }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        nodes {
          id
          body
          frontmatter {
            clinical
            title
          }
        }
      }
    }
  `)

  const { all, clinical } = data

  return <Faq data={[...all.nodes, ...clinical.nodes]} className="FaqClinical" />
}
